@keyframes flashAnimation {
    from {
        opacity: 0.75;
    }

    to {
        opacity: 0;
    }
}

.camera-video {
    /* position: absolute; */
    object-fit: cover;
}

/* video::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
} */

.camera-container {
    width: 100%;
    overflow: hidden;
    /* min-height: 300px; */
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

/* .camera-overlay {
    position: absolute;
    top: 20px;
    right: 20px;
    bottom: 20px;
    left: 20px;
    box-shadow: 0px 0px 20px 56px rgba(0, 0, 0, 0.6);
    border: 1px solid #ffffff;
    border-radius: 10px;
} */

.camera-overlay.hidden {
    display: none;
}

/* .camera-flash {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    opacity: 0;
} */

.preview img {
    position: absolute;
    border: 5px solid #ddd;
    z-index: 1;
    border-radius: 10px;
}

#preview-canvas {
    position: absolute;
    border: 5px solid transparent;
    z-index: 99;
}

#camera-canvas {
    position: absolute;
    border: 5px solid transparent;
    z-index: 99;
    width: 100%;
    height: 100%;
}

.camera-face-overlay {
    position: absolute;
    top: 20px;
    right: 20px;
    bottom: 20px;
    left: 20px;
    box-shadow: 100px rgb(0 0 0 / 60%);
    border: 6px dashed #ddd;
    border-radius: 50%;
    margin: 70px 30px 20px;
}

.camera-face-message {
    font-weight: 600;
    position: absolute;
    top: -50px;
    color: #f00000;
    text-align: center;
    width: 100%;
}